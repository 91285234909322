<template>
  <b-card-code title="Add New">
    <!-- form -->
    <validation-observer ref="RoleAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addRole">
        <!-- Name -->
        <b-form-group label="Name" label-for="name">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              name="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <vue-select-sides
          type="mirror"
          v-model="selected"
          :list="permissions"
        ></vue-select-sides>
        <b-button type="submit" variant="primary" :disabled="invalid">
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vueSelectSides from 'vue-select-sides-forked';
import { required } from "@validations";
import "/node_modules/vue-select-sides-forked/styles/themes/light.scss";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    vueSelectSides,
  },
  data() {
    return {
      name: "",
      permissions: [],
      permissions_o: [],
      selected: [],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      var self = this;
      this.$http({
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/permissions/",
      }).then((res) => {
        console.log(res);
        this.permissions_o = res.data
        res.data.map(function (value, key) {
          let p = {
            value: res.data[key].id,
            label: res.data[key].name,
            codename: res.data[key].codename,
          };
          // console.log(res.data[key].profile.organization)
          self.permissions.push(p);
        });
        console.log('xx',self.permissions_o);
      });
    },
    addRole: function () {
      var self = this;
      this.$refs.RoleAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          let perm_data = [];
          this.selected.map(function (value, key) {
            console.log(value);
            perm_data.push(self.permissions_o.find((o) => o.id === value));
          });
          console.log("final", perm_data);
          let temp = [];
          for (let i of perm_data) i && temp.push(i); // copy each non-empty value to the 'temp' array
          perm_data = temp;
          console.log("final after cleaning", perm_data);
          let data = {
            name: this.name,
            permissions: perm_data,
          };
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "user/groups/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              this.$router.push({ name: "Roles" });
            }
          });
        }
      });
    },
  },
};
</script>
<style >
.vss-list {
    max-height: 400px;
}
</style>
